import React from 'react';

function Linkedin() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <g clipPath="url(#clip0_347_390)">
        <g clipPath="url(#clip1_347_390)">
          <mask
            id="mask0_347_390"
            style={{ maskType: 'luminance' }}
            width="43"
            height="42"
            x="0"
            y="0"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M21 0c11.6 0 21 9.401 21 21s-9.4 21-21 21C9.402 42 0 32.599 0 21S9.402 0 21 0z"
            ></path>
          </mask>
          <g mask="url(#mask0_347_390)">
            <path
              fill="#0077B5"
              d="M-6.561-6.563h55.125v55.126H-6.561V-6.563z"
            ></path>
          </g>
          <mask
            id="mask1_347_390"
            style={{ maskType: 'luminance' }}
            width="22"
            height="22"
            x="10"
            y="9"
            maskUnits="userSpaceOnUse"
          >
            <path
              fill="#fff"
              d="M14.858 11.738c0 1.26-.971 2.281-2.17 2.281-1.2 0-2.17-1.021-2.17-2.281 0-1.26.97-2.284 2.17-2.284 1.199 0 2.17 1.024 2.17 2.284zm.017 4.108h-4.374v14.608h4.374V15.846zm6.985 0h-4.347v14.608h4.347v-7.669c0-4.263 5.276-4.612 5.276 0v7.67H31.5v-9.251c0-7.194-7.806-6.933-9.64-3.39v-1.97.002z"
            ></path>
          </mask>
          <g mask="url(#mask1_347_390)">
            <path
              fill="#fff"
              d="M3.939 2.892h34.125v34.125H3.939V2.892z"
            ></path>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_347_390">
          <path fill="#fff" d="M0 0H42V42H0z"></path>
        </clipPath>
        <clipPath id="clip1_347_390">
          <path
            fill="#fff"
            d="M0 0H402.938V1082.81H0z"
            transform="translate(-182.438 -68.25)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Linkedin;
