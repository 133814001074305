import React, { useEffect, useRef, useState } from 'react';
import Title from '../../components/Title';
import defaultImageList from '../../assets/Photos/DefaultImage.png';
import secondImage from '../../assets/Photos/suvey-fleet-2-max.png';
import thirdImage from '../../assets/Photos/suvey-fleet-3-max.png';
import fourthImage from '../../assets/Photos/suvey-fleet-4-max.png';
import firstImage from '../../assets/Photos/suvey-fleet-1-max.png';
import secondTopImage from '../../assets/Photos/Image-suvey-fleet-top-2.png';
import thirdTopImage from '../../assets/Photos/Image-suvey-fleet-top-3.png';
import fifthImage from '../../assets/Photos/TwinOtters.png';
import fifthTopImage from '../../assets/Photos/FifthTopImage.png';
import firstTopImage from '../../assets/Photos/Image-suvey-fleet-top-1.png';
import LargeDefaultImage from '../../assets/Photos/DefaultImage2.png';
import PageHeading from '../../components/PageHeading/PageHeading';
import fourthTop from '../../assets/Photos/fourthTopImage.png';
import { useLocation } from 'react-router-dom';

function SurveyFleet() {
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const location = useLocation(); // Use the useLocation hook
  const fleetRefs = useRef<Array<HTMLDivElement | null>>([]); // Refs for each fleet item

  const handleItemClick = (index: number) => {
    setActiveIndex(index);
  };

  const surveyFleetImages = [
    firstImage,
    secondImage,
    thirdImage,
    fourthImage,
    fifthImage,
  ];

  const surveyTOPFleetImages = [
    firstTopImage,
    secondTopImage,
    thirdTopImage,
    fourthTop,
    fifthTopImage,
  ];

  const surverFleetHeadings = [
    'Twin Otter VT-ELX',
    'Eurocopter AS 350B3',
    '06B-L4 Long Ranger',
    'Beechcraft King Air C90',
    'Beechcraft King Air B-200',
  ];

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const fleetIndex = params.get('fleet');

    if (fleetIndex) {
      const index = parseInt(fleetIndex, 10); // Convert to number
      if (!isNaN(index) && index >= 0 && index < surverFleetHeadings.length) {
        setActiveIndex(index);
        if (fleetRefs.current[index]) {
          fleetRefs.current[index]?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        }
      }
    }
  }, [location.search]);

  useEffect(() => {
    if (fleetRefs.current[activeIndex]) {
      fleetRefs.current[activeIndex]?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }, [activeIndex, location.search]); // Scroll to the active fleet item

  const surveyFleetDesc = [
    <div className="font-bold">
      <ul className="list-disc text-[12px] md:text-[18px]">
        <li>Max Take-off Weight: 12,500 lb (5,670 kg)</li>
        <li>Crew: 1 or 2</li>
        <li>Fuel Capacity: 378 US Gallons</li>
        <li>Engines: Two Pratt & Whitney Canada PT6A-34</li>
        <li>Features: ADAHRS, MMDR, TCAS I, DME</li>
      </ul>
    </div>,
    <div className="font-bold">
      <ul className="list-disc text-[12px] md:text-[18px]">
        <li>Exterior Height: 10 ft 4 in</li>
        <li>Wing Span: 7 ft 8 in</li>
        <li>Length: 35 ft 11 in</li>
        <li>External Baggage: 35 cu ft</li>
        <li>
          Single-engine light utility helicopter powered by Turbomeca Arriel 1B
          engine
        </li>
        <li>High-altitude capabilities with a service ceiling of 15,100 ft</li>
        <li>Designed with a quiet cabin to meet European noise regulations</li>
      </ul>
    </div>,
    <div className="font-bold">
      <ul className="list-disc text-[12px] md:text-[18px]">...</ul>
    </div>,
    <div className="font-bold">
      <ul className="list-disc text-[12px] md:text-[18px]">
        <li>
          Comprises twin-turboprop models: King Airs (Model 90 and 100 series)
          and Super King Airs (T-tail Model 200 and 300 series)
        </li>
        <li>Crew: 1-2</li>
        <li>Length: 35 ft 6 in to 39 ft 11 in</li>
        <li>Powerplant: 2X PT6A-135A</li>
        <li>Propeller: Hartzell HC-E4N-3N</li>
        <li>Cruise TAS: 226 kn (416 km/hr)</li>
        <li>Stall IAS (Flaps down): 75 kn (135 km/hr)</li>
        <li>Ceiling: 30,000 ft</li>
        <li>Climb Rate: 2,000 ft/min</li>
      </ul>
    </div>,
    <div className="font-bold">
      <ul className="list-disc text-[12px] md:text-[18px]">
        <li>Crew: 1-2</li>
        <li>Length: 43 ft 10 in to 46 ft 8 in</li>
        <li>Powerplant: 2X PT6A-42-52</li>
        <li>Propeller: Composite SWEPT BLADE</li>
        <li>Cruise Speed: 289 kn (535 km/h)</li>
        <li>Stall Speed (Flaps Down): 77 kn (138 km/h)</li>
        <li>Ceiling: 35,000 ft</li>
        <li>Climb Rate: 2,460 ft/min</li>
      </ul>
    </div>,
  ];

  return (
    <div className="mb-12">
      <div className="flex flex-row  gap-2 md:gap-6 mt-4 mx-2 overflow-x-auto">
        {surverFleetHeadings.map((heading, index: any) => (
          <div
            key={index}
            id={index}
            className={`rounded-md p-2 md:p-4 ${
              activeIndex === index ? 'bg-primary text-white' : 'text-primary'
            }`}
            onClick={() => handleItemClick(index)}
            ref={(el) => (fleetRefs.current[index] = el)} // Assign ref to each fleet item
          >
            <img
              src={surveyTOPFleetImages[index]}
              className="rounded-md max-w-32 md:max-w-max md:h-[158px] object-cover" // Use Tailwind utility for fixed height
            />
            <div className="text-xs lg:text-lg">{heading}</div>
          </div>
        ))}
      </div>
      <div className="mt-12 mx-24">
        <span className="text-2xl font-bold text-primary">
          {surverFleetHeadings[activeIndex]}
        </span>
        <div className="md:flex gap-6 mt-4">
          <div className="p-1 rounded-lg bg-white">
            <img src={surveyFleetImages[activeIndex]} className="rounded-md" />
          </div>

          <div className="font-bold">{surveyFleetDesc[activeIndex]}</div>
        </div>
      </div>
    </div>
  );
}

export default SurveyFleet;
