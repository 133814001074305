import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import OfficialLogo from '../assets/Icons/OfficialLogo';
import Linkedin from '../assets/Icons/Linkedin';
import Popover from './Popover';
import DropdownIcon from '../assets/Icons/DropdownArrow';
import Facebook from '../assets/Icons/Facebook';
import Twitter from '../assets/Icons/Twitter';
import { FaHamburger, FaTimes } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';

const technologies = [
  'High Resolution Magnetics',
  'Gamma-Ray-Spectrometry',
  'Scaler Gravity',
  'Full Tensor Gravity (FTG)',
  'Full Tensor Magnetic Gradiometry (FTMG)',
  'Time Domain Electromagnetics (TDEM)',
  'Sub Audio Magnetics (SAM)',
  'Passive Transient Pulse - Electromagnetics',
  'Ground Geophysics',
  'Prospectivity Analysis',
];

const services = [
  'Critical Mineral Exploration',
  'Deep Seated Mineral Exploration',
  'Hydrocarbon Exploration',
  'Ground Water Exploration',
  'Environmental Mapping',
  'Early Stage Exploration Advisory',
];

const surveyFleetHeadings = [
  'Twin Otter VT-ELX',
  'Eurocopter AS 350B3',
  '06B-L4 Long Ranger',
  'Beechcraft King Air C90',
  'Beechcraft King Air B-200',
];

function Navbar(props: any) {
  const navigate = useNavigate();
  const location = useLocation();
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [servicesPopoverVisible, setServicesPopoverVisible] =
    useState<boolean>(false);
  const [fleetPopoverVisible, setFleetPopoverVisible] =
    useState<boolean>(false);
  const [mobileMenuVisible, setMobileMenuVisible] = useState<boolean>(false);
  const [expandedTechnologies, setExpandedTechnologies] =
    useState<boolean>(false);
  const [expandedServices, setExpandedServices] = useState<boolean>(false);
  const [expandedFleet, setExpandedFleet] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const menuRef = useRef<any>(null);

  // Close menu when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMobileMenuVisible(false);
      }
    };

    if (mobileMenuVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [mobileMenuVisible, setMobileMenuVisible]);

  // Update scroll position
  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Close all popovers on route change
    setPopoverVisible(false);
    setServicesPopoverVisible(false);
    setFleetPopoverVisible(false);
  }, [location]);

  const handleNavigation = (path: string, index: number) => {
    navigate(`${path}?index=${index}`);
    setMobileMenuVisible(false);
  };

  const getNavClass = (path: string) => {
    console.log('path==', path, location.pathname);
    return location.pathname === path
      ? 'nav-active hover:text-white-600 text-navbar-title text-sm'
      : 'nav-inactive text-navbar-title hover:text-secondary-light text-sm';
  };

  return (
    <div>
      <div className="hidden md:flex gap-8 bg-primary h-fit px-4 md:px-[32px] py-[14px] text-white items-center justify-between">
        <OfficialLogo height={40} width={40} />
        <nav className="flex gap-8">
          <Link
            to="/"
            className={
              location.pathname === '/' || location.pathname === '/home'
                ? 'nav-active hover:text-white-600 text-navbar-title'
                : 'nav-inactive text-navbar-title hover:text-secondary-light'
            }
          >
            Home
          </Link>

          {/* No Popover for About Us */}
          <Link
            to="/about-us"
            className={
              location.pathname === '/about-us'
                ? 'nav-active hover:text-white-600 text-navbar-title'
                : 'nav-inactive text-navbar-title hover:text-secondary-light'
            }
          >
            About Us
          </Link>

          <Link
            to="/leadership"
            className={
              location.pathname === '/leadership'
                ? 'nav-active hover:text-white-600 text-navbar-title'
                : 'nav-inactive text-navbar-title hover:text-secondary-light'
            }
          >
            Leadership
          </Link>

          {/* Technologies Dropdown */}
          <Popover
            isVisible={popoverVisible}
            setIsVisible={setPopoverVisible}
            content={
              <div>
                {technologies.map((tech, index) => (
                  <Link
                    key={index}
                    to={`/our-technologies?technology=${encodeURIComponent(
                      index
                    )}`}
                    className="block p-2 hover:bg-gray-100"
                  >
                    {tech}
                  </Link>
                ))}
              </div>
            }
          >
            <div className="flex gap-1 justify-center items-center">
              <span
                className={
                  location.pathname === '/our-technologies'
                    ? 'nav-active hover:text-white-600 text-navbar-title'
                    : 'nav-inactive text-navbar-title hover:text-secondary-light'
                }
                onClick={() => setPopoverVisible(!popoverVisible)}
              >
                Technologies
              </span>
              <DropdownIcon
                opacity={
                  location.pathname === '/our-technologies' ? '' : 'opacity-60'
                }
              />
            </div>
          </Popover>

          {/* Services Dropdown */}
          <Popover
            isVisible={servicesPopoverVisible}
            setIsVisible={setServicesPopoverVisible}
            content={
              <div>
                {services.map((service, index) => (
                  <Link
                    key={index}
                    to={`/services?service=${encodeURIComponent(index)}`}
                    className="block p-2 hover:bg-gray-100"
                  >
                    {service}
                  </Link>
                ))}
              </div>
            }
          >
            <div className="flex gap-1 justify-center items-center">
              <span
                className={
                  location.pathname === '/services'
                    ? 'nav-active hover:text-white-600 text-navbar-title'
                    : 'nav-inactive text-navbar-title hover:text-secondary-light'
                }
                onClick={() =>
                  setServicesPopoverVisible(!servicesPopoverVisible)
                }
              >
                Services
              </span>
              <DropdownIcon
                opacity={location.pathname === '/services' ? '' : 'opacity-60'}
              />
            </div>
          </Popover>

          {/* Survey Fleet Dropdown */}
          <Popover
            isVisible={fleetPopoverVisible}
            setIsVisible={setFleetPopoverVisible}
            content={
              <div>
                {surveyFleetHeadings.map((fleet, index) => (
                  <Link
                    key={index}
                    to={`/survey-fleet?fleet=${encodeURIComponent(index)}`}
                    className="block p-2 hover:bg-gray-100"
                  >
                    {fleet}
                  </Link>
                ))}
              </div>
            }
          >
            <div className="flex gap-1 justify-center items-center">
              <span
                className={
                  location.pathname === '/survey-fleet'
                    ? 'nav-active hover:text-white-600 text-navbar-title'
                    : 'nav-inactive text-navbar-title hover:text-secondary-light'
                }
                onClick={() => setFleetPopoverVisible(!fleetPopoverVisible)}
              >
                Survey Fleet
              </span>
              <DropdownIcon
                opacity={
                  location.pathname === '/survey-fleet' ? '' : 'opacity-60'
                }
              />
            </div>
          </Popover>
          <Link
            to="/contact-us"
            className={
              location.pathname === '/contact-us'
                ? 'nav-active hover:text-white-600 text-navbar-title'
                : 'nav-inactive text-navbar-title hover:text-secondary-light'
            }
          >
            Contact Us
          </Link>
        </nav>
        <div className="hidden md:flex gap-4">
          <div className="w-fit flex items-center">
            <Linkedin />
          </div>
          <div className="w-fit flex items-center">
            <Facebook />
          </div>
          <div className="w-fit flex items-center">
            <Twitter />
          </div>
        </div>
      </div>
      <div
        className={`md:hidden fixed top- 0 left-0 p-4 w-full mb-4 z-10  ${
          scrollPosition > 30 ? 'bg-[#5b5a49]' : 'bg-transparent'
        }`}
      >
        <GiHamburgerMenu
          onClick={() => setMobileMenuVisible(true)}
          className={`cursor-pointer `}
          color="white"
        />
      </div>
      {/* Mobile Menu */}
      {mobileMenuVisible && (
        <div
          className={`fixed top-0 left-0 w-3/4 h-full bg-primary text-white z-50 transform transition-transform duration-2000 ease-in-out overflow-y-auto ${
            mobileMenuVisible ? 'translate-x-0' : '-translate-x-full'
          }`}
          ref={menuRef}
        >
          <div className="flex justify-between p-4">
            <OfficialLogo height={40} width={40} />
            <FaTimes
              onClick={() => setMobileMenuVisible(false)}
              className="cursor-pointer"
            />
          </div>

          <nav className="flex flex-col gap-4 p-4">
            <Link
              to="/"
              onClick={() => handleNavigation('/', 0)}
              className={`${getNavClass('/')}`}
            >
              Home
            </Link>
            <Link
              to="/about-us"
              onClick={() => handleNavigation('/about-us', 1)}
              className={`${getNavClass('/about-us')}`}
            >
              About Us
            </Link>
            <Link
              to="/leadership"
              onClick={() => handleNavigation('/leadership', 2)}
              className={`${getNavClass('/leadership')}`}
            >
              Leadership
            </Link>

            {/* Expandable Technologies */}
            <div>
              <div
                className="flex justify-between"
                onClick={() => setExpandedTechnologies(!expandedTechnologies)}
              >
                <span className={`${getNavClass('/our-technologies')}`}>
                  Technologies
                </span>
                <DropdownIcon />
              </div>
              {expandedTechnologies && (
                <div className="pl-4">
                  {technologies.map((tech, index) => (
                    <Link
                      key={index}
                      to={`/our-technologies?technology=${index}`}
                      onClick={() =>
                        handleNavigation('/our-technologies', index)
                      }
                      className={`block py-2 nav-inactive`}
                    >
                      {tech}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Expandable Services */}
            <div>
              <div
                className="flex justify-between"
                onClick={() => setExpandedServices(!expandedServices)}
              >
                <span className="nav-inactive">Services</span>
                <DropdownIcon />
              </div>
              {expandedServices && (
                <div className="pl-4">
                  {services.map((service, index) => (
                    <Link
                      key={index}
                      to={`/services?service=${index}`}
                      onClick={() => handleNavigation('/services', index)}
                      className="block py-2 nav-inactive"
                    >
                      {service}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            {/* Expandable Survey Fleet */}
            <div>
              <div
                className="flex justify-between"
                onClick={() => setExpandedFleet(!expandedFleet)}
              >
                <span className="nav-inactive">Survey Fleet</span>
                <DropdownIcon />
              </div>
              {expandedFleet && (
                <div className="pl-4">
                  {surveyFleetHeadings.map((fleet, index) => (
                    <Link
                      key={index}
                      to={`/survey-fleet?fleet=${index}`}
                      className="block py-2 nav-inactive"
                      onClick={() => handleNavigation('/survey-fleet', index)}
                    >
                      {fleet}
                    </Link>
                  ))}
                </div>
              )}
            </div>

            <Link
              to="/contact-us"
              onClick={() => handleNavigation('/contact-us', 5)}
              className="nav-inactive"
            >
              Contact Us
            </Link>
          </nav>
        </div>
      )}
    </div>
  );
}

export default Navbar;
