import React from 'react';

function Work() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="24"
      fill="none"
      viewBox="0 0 26 24"
    >
      <path
        fill="#fff"
        d="M6.766.625v1.971L.562 6.636v16.739H25.38V6.635l-6.205-4.039V.625H6.766zm2.069 2.068h8.272v7.95l-4.136 2.682-4.136-2.682v-7.95zm1.034 2.068V6.83h6.204V4.76H9.87zm-3.103.291v4.234L3.502 7.185l3.264-2.133zm12.41 0l3.263 2.133-3.264 2.1V5.053zM9.868 7.864v2.068h6.204V7.864H9.87zM2.63 9.092l10.34 6.689 10.342-6.69v12.216H2.63V9.092z"
      ></path>
    </svg>
  );
}

export default Work;
