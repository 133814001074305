function Subtitle({
    text,
    color,
    weight,
    size,
}: {
    text: string;
    color?: string;
    weight?: string;
    size?: number;
}) {
    return (
        <div className={`text-[${size || 4}px] ${color ? color : 'text-primary'} ${weight ? weight : ''}`}>
            {text}
        </div>
    );
}

export default Subtitle;
