import React from 'react';

function DropdownIcon({
  opacity,
  color,
}: {
  opacity?: string;
  color?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="8"
      fill="none"
      viewBox="0 0 12 8"
      className={opacity}
    >
      <path
        fill={color || '#fff'}
        d="M11.883 1.222a.38.38 0 010 .553L6.278 7.38a.38.38 0 01-.553 0L.12 1.775a.38.38 0 010-.553L.722.62a.38.38 0 01.553 0l4.727 4.727L10.729.62a.38.38 0 01.553 0l.601.602z"
      ></path>
    </svg>
  );
}

export default DropdownIcon;
