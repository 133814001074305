import React from 'react';
import './style.css';
import HeroImage from '../../assets/Photos/HeroImage.png';

const HeroSection = () => {
  return (
    <section className="hero-section flex flex-col md:flex-row mt-8">
      <div className="hero-content">
        <h1 className="font-bold text-lg md:text-5xl md:leading-snug">
          Uncovering rare earth elements and underground water with cutting-edge
          exploration across vast, untapped territories
        </h1>
      </div>
      <div>
        {/* Carousel component or images */}
        <img src={HeroImage} alt="Exploration Aircraft" height={20} />
      </div>
    </section>
  );
};

export default HeroSection;
