import React from 'react';

function Title({
  text,
  color,
  weight,
  size,
}: {
  text: string;
  color?: string;
  weight?: string;
  size?: number;
}) {
  return (
    <div
      className={`text-${size || 4}xl ${color ? color : 'text-primary'} ${
        weight ? weight : ''
      }`}
    >
      {text}
    </div>
  );
}

export default Title;
