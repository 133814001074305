import React from 'react';

function Phone() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill="#fff"
        d="M16.016.424v1.928c1.908 0 3.695.482 5.361 1.447a10.74 10.74 0 013.795 3.796 10.542 10.542 0 011.445 5.364h1.928c0-2.27-.572-4.38-1.717-6.328a12.334 12.334 0 00-4.487-4.49A12.262 12.262 0 0016.016.424zM6.048 3.316c-.542 0-1.014.171-1.416.513L1.53 6.993l.09-.06a2.973 2.973 0 00-.993 1.566 3.08 3.08 0 00.12 1.808c.563 1.567 1.315 3.174 2.26 4.821a29.44 29.44 0 004.727 6.117c2.932 2.953 6.576 5.283 10.933 6.99h.03c.582.201 1.164.242 1.747.121a3.41 3.41 0 001.596-.783l3.041-3.044c.402-.401.603-.894.603-1.476 0-.603-.2-1.105-.603-1.507l-3.945-3.977c-.401-.402-.903-.603-1.506-.603-.602 0-1.104.201-1.505.603l-1.898 1.928c-1.526-.723-2.85-1.617-3.975-2.681-1.125-1.085-2.018-2.401-2.68-3.948l1.927-1.928c.422-.442.632-.964.632-1.567 0-.623-.24-1.125-.722-1.507l.09.09L7.463 3.83a2.116 2.116 0 00-1.415-.513zm9.968.965v1.928a6.53 6.53 0 013.374.904 6.628 6.628 0 012.47 2.47 6.538 6.538 0 01.902 3.376h1.928a8.54 8.54 0 00-1.175-4.37 9 9 0 00-3.132-3.133 8.528 8.528 0 00-4.367-1.175zm-9.968.964c.06 0 .13.03.21.09l3.946 4.038c.02.08 0 .15-.06.21l-2.861 2.833.21.603.392.844c.321.683.693 1.346 1.114 1.988a11.719 11.719 0 001.928 2.32c.943.925 2.078 1.768 3.403 2.532.662.381 1.225.662 1.686.843l.603.271 2.921-2.922c.04-.04.07-.06.09-.06.02 0 .05.02.09.06l4.067 4.067c.04.04.06.07.06.09l-.06.061-3.012 2.983c-.442.382-.924.482-1.446.302-4.096-1.587-7.509-3.757-10.24-6.509a28.646 28.646 0 01-4.426-5.755c-.904-1.547-1.607-3.043-2.109-4.49v-.03c-.08-.18-.09-.392-.03-.633.06-.26.17-.462.332-.602l2.981-3.044c.06-.06.13-.09.21-.09zm9.968 2.893v1.928c.804 0 1.486.281 2.048.844a2.79 2.79 0 01.844 2.049h1.927c0-.864-.22-1.668-.663-2.41A4.564 4.564 0 0018.427 8.8a4.63 4.63 0 00-2.41-.662z"
      ></path>
    </svg>
  );
}

export default Phone;
