import React from 'react';

function OfficialLogo({ height, width }: { height?: number; width?: number }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '40px'} // Set to 100% for responsive width
      height={height || '60px'} // Set to auto for responsive height
      viewBox="0 0 90 90"
      preserveAspectRatio="xMidYMid meet" // Ensures proper scaling
    >
      <circle cx="45" cy="45" r="45" fill="#fff"></circle>
      <path
        fill="#0C1A96"
        d="M70.527 69.538a8.835 8.835 0 01-2.392-.937 9.38 9.38 0 01-2.115-1.875 9.608 9.608 0 01-1.357-2.518l-3.297-8.535a20.26 20.26 0 01-5.632 8.44c.212.59.422 1.182.633 1.775.115.296.195.605.24.919.047.29.074.582.079.876 0 .585-.684 1.077-2.053 1.476-1.369.398-3.09.664-5.166.799v2.828H73v-2.828a12.229 12.229 0 01-2.473-.42z"
      ></path>
      <path
        fill="url(#paint0_linear_343_30)"
        d="M18.703 74.296c.307-.344.297-1.833.4-2.308.25-1.232.607-2.44 1.067-3.61a29.722 29.722 0 013.238-5.876c2.725-3.806 6.354-6.883 10.167-9.557 5.639-3.947 11.887-6.633 16.83-11.552a40.579 40.579 0 009.55-15.043 39.617 39.617 0 002.114-9.668 31.4 31.4 0 01.781 6.715 32.69 32.69 0 01-2.014 11.31c-2.65 7.128-7.89 12.402-14.117 16.587a85.146 85.146 0 01-8.159 4.768c-.582.304-2.148 1.007-4.223 2.134a74.2 74.2 0 00-3.232 1.863C37.08 58.921 49.858 55.495 58 44.324a35.002 35.002 0 003.16-5.258 42.004 42.004 0 001.609-3.95 31.795 31.795 0 01-.693 5.157c-.543 2.483-1.783 8.122-6.064 12.898-3.368 3.757-8.041 5.995-12.675 7.785a67.243 67.243 0 01-10.928 3.268c4.5.304 10.628.222 16.598-2.686 3.657-1.776 5.827-3.39 6.354-3.844a22.816 22.816 0 006.174-8.684c-.36 2.791-1.652 9.674-7.143 14.039-1.984 1.58-4.108 2.51-5.18 2.971-4.432 1.909-7.654 2.04-10.886 2.275-7.1.519-14.54.294-19.622 6.001z"
      ></path>
      <path
        fill="#0C1A96"
        d="M45.848 14.882l-.019-.012a1.772 1.772 0 00-3.167.02c-2.686 6.915-5.745 14.8-9.175 23.652a2430.348 2430.348 0 01-6.503 16.62l-.544 1.737a26.002 26.002 0 015.405-4.19l1.652-4.197 5.178-13.454 3.031-7.88 5.584 14.307.13.319.04-.027a22.678 22.678 0 006.21-6.662l-7.822-20.233z"
      ></path>
      <defs>
        <linearGradient
          id="paint0_linear_343_30"
          x1="18.703"
          x2="62.85"
          y1="45.491"
          y2="45.491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B9852C"></stop>
          <stop offset="0.05" stopColor="#C28D2A"></stop>
          <stop offset="0.12" stopColor="#D9A323"></stop>
          <stop offset="0.17" stopColor="#EEB61D"></stop>
          <stop offset="0.44" stopColor="#FDF289"></stop>
          <stop offset="0.73" stopColor="#EEB61D"></stop>
          <stop offset="0.89" stopColor="#ECCA65"></stop>
          <stop offset="1" stopColor="#B9852C"></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

export default OfficialLogo;
