import React from 'react';
import HeroSection from '../../components/HeroComponent/HeroComponent';
import Title from '../../components/Title';
import carouseImage1 from '../../assets/Photos/carousel-pic-1.png';
import carouseImage2 from '../../assets/Photos/carousel-pic-2.png';
import carouseImage3 from '../../assets/Photos/carousel-pic-3.png';
import { Link } from 'react-router-dom';
import ContacusForm from '../../components/ContactUsForm';
import { Carousel } from 'react-responsive-carousel';
import CarouselCustom from '../../components/Carousel/Carousel';

function Home() {
  const items = [
    <div className="flex items-center justify-center">Item 1</div>,
    <div className="flex items-center justify-center">Item 2</div>,
    <div className="flex items-center justify-center">Item 3</div>,
  ];
  return (
    <div className="mx-2 md:mx-12">
      <HeroSection />
      <div className="mt-[30px] md:mt-[70px] mb-6 md:mb-[94px]">
        <span className="flex justify-center font-bold text-xl md:text-[48px] leading-[60px] text-primary mt-6 mb-4">
          Our Bespoke Offering
        </span>
        <span className="flex w-[100%] md:w-[70%] text-[12px] md:text-[20px] leading-4 md:leading-[32px] mx-auto text-center">
          We are pioneering India's journey to self-reliance in natural resource
          exploration. As the nation's first comprehensive early-stage
          exploration company, AGI leverages state-of-the-art, patented
          technologies and a highly skilled workforce to discover critical and
          deep-seated resources. With a global footprint spanning four
          continents, AGI is committed to transforming India into the world's
          foremost exploration hotspot, driving innovation and excellence in
          geoscience.
        </span>
        <div className="flex justify-center mt-12">
          <Link
            to="/about-us"
            className="font-bold text-[12px] md:text-[20px] leading-none cursor-pointer text-primary border-2 p-2 md:p-4 rounded-md border-primary pr-6 pl-6"
          >
            Read More &gt;
          </Link>
        </div>
        <div className="flex gap-4 justify-center items-center mt-12">
          {/* <img src={carouseImage1} alt="Carousel 1" />
          <img src={carouseImage2} alt="Carousel 2" />
          <img src={carouseImage3} alt="Carousel 3" /> */}
          <CarouselCustom />
        </div>
      </div>
      <div className="mb-[94px]">
        <ContacusForm />
      </div>
    </div>
  );
}

export default Home;
