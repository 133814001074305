import React from 'react';
import { Link } from 'react-router-dom';
import OfficialLogo from '../../assets/Icons/OfficialLogo';

function Footer() {
  return (
    <>
      <div className="bg-primary flex flex-col md:flex-row gap-6 md:gap-20 text-white justify-center px-4 md:px-20 py-[20px] md:py-[60px]">
        <div className="flex flex-row w-[100%] md:w-[25.5vw] md:h-28 items-center gap-4">
          <div className="w-fit">
            <OfficialLogo height={60} width={60} />
          </div>
          <span className="text-[12px] md:text-[18px] leading-1 items-center flex">
            Airborne Geoscience International (AGI) is pioneering India's
            journey to self-reliance in natural resource exploration.
          </span>
        </div>
        <div className="flex flex-row gap-2 md:gap-6 justify-center flex-wrap md:w-[36vw] text-xs md:text-[18px]">
          <Link
            to="/"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Home
          </Link>
          <Link
            to="/about-us"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            About Us
          </Link>
          <Link
            to="/our-technologies"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Technology
          </Link>
          <Link
            to="/leadership"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Leadership
          </Link>
          <Link
            to="/services"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Services
          </Link>
          <Link
            to="/contact-us"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Contact Us
          </Link>
          <Link
            to="/privacy-policy"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-conditions"
            className=" leading-none items-center flex hover:text-secondary-light"
          >
            Terms & Conditions
          </Link>
        </div>
      </div>
      <div className="bg-white h-[20px] md:h-[54px] flex items-center justify-center">
        <span className="text-primary text-xs md:text-[18px]">
          &copy; {new Date().getFullYear()} AGI - Airborne Geoscience
          International, All Rights Reserved
        </span>
      </div>
    </>
  );
}

export default Footer;
