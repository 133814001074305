import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="p-4 md:p-8 mx-6 md:mx-24">
      <h1 className="font-bold text-2xl md:text-3xl mb-6">
        Terms And Conditions
      </h1>
      {/* 1. Introduction */}
      <h2 className="font-medium text-[12px] md:text-[18px]">
        1. Introduction
      </h2>
      <p className="text-[12px] md:text-[18px]">
        Welcome to AGI. These Terms and Conditions ("Terms") govern your use of
        our website
        <a href="http://www.AGIPLC.COM" className="text-blue-600">
          {' '}
          www.AGIPLC.COM{' '}
        </a>
        (the "Site") and any services, content, or products provided through the
        Site. By accessing or using our Site, you agree to be bound by these
        Terms. If you do not agree with any part of these Terms, please do not
        use our Site.
      </p>

      {/* 2. Use of the Site */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        2. Use of the Site
      </h2>
      <ul className="list-disc pl-6 text-[12px] md:text-[18px]">
        <li>
          <span className="font-medium">Eligibility:</span> You must be at least
          18 years old to use this Site. By using this Site, you represent that
          you meet this age requirement.
        </li>
        <li>
          <span className="font-medium">Account Creation:</span> To access
          certain features, you may be required to create an account. You agree
          to provide accurate, current, and complete information during the
          registration process and to keep your account information updated.
        </li>
        <li>
          <span className="font-medium">Account Security:</span> You are
          responsible for maintaining the confidentiality of your account
          credentials and for all activities that occur under your account.
          Please notify us immediately of any unauthorized use of your account.
        </li>
        <li>
          <span className="font-medium">Prohibited Activities:</span> You agree
          not to engage in any of the following activities:
          <ul className="list-disc pl-6">
            <li>Violating any applicable laws or regulations</li>
            <li>
              Infringing on the rights of others, including intellectual
              property rights
            </li>
            <li>
              Uploading or transmitting viruses, malware, or any other harmful
              code
            </li>
            <li>Interfering with the proper functioning of the Site</li>
          </ul>
        </li>
      </ul>

      {/* 3. Intellectual Property */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        3. Intellectual Property
      </h2>
      <ul className="list-disc pl-6 text-[12px] md:text-[18px]">
        <li>
          <span className="font-medium">Ownership:</span> All content on the
          Site, including text, graphics, logos, images, and software, is the
          property of AGI or its licensors and is protected by intellectual
          property laws.
        </li>
        <li>
          <span className="font-medium">Limited License:</span> You are granted
          a limited, non-exclusive, non-transferable license to access and use
          the Site for personal, non-commercial purposes. You may not reproduce,
          distribute, or create derivative works from any content on the Site
          without our prior written consent.
        </li>
      </ul>

      {/* 4. Purchases and Payments */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        4. Purchases and Payments
      </h2>
      <ul className="list-disc pl-6 text-[12px] md:text-[18px]">
        <li>
          <span className="font-medium">Pricing:</span> All prices on the Site
          are listed in [Currency] and are subject to change without notice.
        </li>
        <li>
          <span className="font-medium">Payment:</span> You agree to provide
          accurate and complete payment information for any purchases made on
          the Site. We reserve the right to refuse or cancel any order if fraud
          or an unauthorized or illegal transaction is suspected.
        </li>
        <li>
          <span className="font-medium">Refunds:</span> Our refund policy is
          outlined separately on the Site. Please review it before making any
          purchases.
        </li>
      </ul>

      {/* 5. Third-Party Links */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        5. Third-Party Links
      </h2>
      <p className="text-[12px] md:text-[18px]">
        The Site may contain links to third-party websites or services that are
        not owned or controlled by AGI. We are not responsible for the content,
        privacy policies, or practices of any third-party websites or services.
        You acknowledge and agree that AGI shall not be liable for any damages
        or losses caused by your use of or reliance on any such third-party
        content or services.
      </p>

      {/* 6. Disclaimer of Warranties */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        6. Disclaimer of Warranties
      </h2>
      <p className="text-[12px] md:text-[18px]">
        The Site and all content, products, and services provided through the
        Site are offered "as is" and "as available," without warranties of any
        kind, either express or implied. AGI disclaims all warranties, including
        but not limited to, implied warranties of merchantability, fitness for a
        particular purpose, and non-infringement.
      </p>

      {/* 7. Limitation of Liability */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        7. Limitation of Liability
      </h2>
      <p className="text-[12px] md:text-[18px]">
        To the fullest extent permitted by law, AGI shall not be liable for any
        indirect, incidental, special, consequential, or punitive damages, or
        any loss of profits or revenues, whether incurred directly or
        indirectly, arising from your use of the Site or the products or
        services obtained through the Site.
      </p>

      {/* 8. Indemnification */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        8. Indemnification
      </h2>
      <p className="text-[12px] md:text-[18px]">
        You agree to indemnify, defend, and hold harmless AGI, its affiliates,
        officers, directors, employees, and agents from and against any claims,
        liabilities, damages, losses, and expenses, including reasonable
        attorneys' fees, arising out of or in any way connected with your use of
        the Site or violation of these Terms.
      </p>

      {/* 9. Governing Law */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        9. Governing Law
      </h2>
      <p className="text-[12px] md:text-[18px]">
        These Terms shall be governed by and construed in accordance with the
        laws of [Your Jurisdiction], without regard to its conflict of law
        principles. Any legal action or proceeding arising out of or relating to
        these Terms shall be brought exclusively in the courts located in [Your
        Jurisdiction].
      </p>

      {/* 10. Changes to These Terms */}
      <h2 className="font-medium text-[12px] md:text-[18px] mt-6">
        10. Changes to These Terms
      </h2>
      <p className="text-[12px] md:text-[18px]">
        We may update these Terms from time to time. We will notify you of any
        significant changes by posting the new Terms on this page with an
        updated effective date. Your continued use of the Site after any changes
        constitutes your acceptance of the new Terms.
      </p>
    </div>
  );
};

export default TermsAndConditions;
