import React, { useEffect } from 'react';
import './styles/global.css';
import Navbar from './components/navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Footer from './components/Footer/Footer';
import AboutUs from './pages/About Us/AboutUs';
import Leadership from './pages/Leadership/Leadership';
import Technologies from './pages/Our Technologies/Technologies';
import Services from './pages/Services/OurServices';
import SurveyFleet from './pages/Survey Fleet/SurveyFleet';
import ContactUs from './pages/ContactUs/ContactUs';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import SvgDefinitions from './SvgDefinitions';
import PrivacyPolicy from './components/PrivacyPolicy';
import TermsAndConditions from './components/TermsAndConditions';

function App() {
  useEffect(() => {
    console.log(window.location.pathname, 'path');
  }, []); // Add dependency array to run effect only once

  return (
    <Router>
      <SvgDefinitions />
      <ScrollToTop />
      <div className="min-h-screen flex flex-col gradient-background">
        <div>
          <Navbar />
        </div>

        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/leadership" element={<Leadership />} />
            <Route path="/our-technologies" element={<Technologies />} />
            <Route path="/services" element={<Services />} />
            <Route path="/survey-fleet" element={<SurveyFleet />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsAndConditions />} />
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
