import React from 'react';

function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="42"
      fill="none"
      viewBox="0 0 42 42"
    >
      <mask
        id="mask0_347_987"
        style={{ maskType: 'luminance' }}
        width="42"
        height="42"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M21 0c11.599 0 21 9.401 21 21s-9.401 21-21 21S0 32.599 0 21 9.401 0 21 0z"
        ></path>
      </mask>
      <g mask="url(#mask0_347_987)">
        <path
          fill="#4267B2"
          d="M-6.563-6.563h55.126v55.126H-6.563V-6.563z"
        ></path>
      </g>
      <mask
        id="mask1_347_987"
        style={{ maskType: 'luminance' }}
        width="12"
        height="22"
        x="15"
        y="10"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M18.375 17.5H15.75V21h2.625v10.5h4.375V21h3.186l.314-3.5h-3.5V16.04c0-.836.168-1.166.976-1.166h2.524V10.5h-3.332c-3.146 0-4.543 1.385-4.543 4.039v2.96z"
        ></path>
      </mask>
      <g mask="url(#mask1_347_987)">
        <path fill="#fff" d="M9.188 3.938h23.625v34.124H9.188V3.938z"></path>
      </g>
    </svg>
  );
}

export default Facebook;
