import React from 'react';

interface PopoverProps {
  children: React.ReactNode; // The trigger element (e.g., "Technologies" link)
  content: React.ReactNode; // The content to display in the popover (e.g., dropdown items)
  isVisible: boolean; // Controlled visibility from the parent component
  setIsVisible: (visible: boolean) => void; // Function to control visibility
}

const Popover: React.FC<PopoverProps> = ({
  children,
  content,
  isVisible,
  setIsVisible,
}) => {
  const showPopover = () => setIsVisible(true);
  const hidePopover = () => setIsVisible(false);

  return (
    <div
      className="relative inline-block"
      onMouseEnter={showPopover}
      onMouseLeave={hidePopover}
    >
      {/* Trigger element */}
      <div className="cursor-pointer">{children}</div>

      {/* Popover content */}
      {isVisible && (
        <div
          className="absolute top-5 left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-50 border text-primary"
          onMouseEnter={showPopover}
          onMouseLeave={hidePopover}
        >
          {content}
        </div>
      )}
    </div>
  );
};

export default Popover;
