import React from 'react';

function Location() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="25"
      fill="none"
      viewBox="0 0 19 25"
    >
      <path
        fill="#fff"
        d="M9.673.33A9.047 9.047 0 00.626 9.377c0 1.67.327 3.397 1.265 4.64L9.673 24.33l7.78-10.312c.853-1.13 1.267-3.128 1.267-4.641A9.047 9.047 0 009.672.33zm0 5.24a3.808 3.808 0 11-.001 7.615 3.808 3.808 0 010-7.615z"
      ></path>
    </svg>
  );
}

export default Location;
