import React from 'react';
import Title from '../../components/Title';
import AboutUsPics from '../../assets/Photos/AboutPics.png';
import PageHeading from '../../components/PageHeading/PageHeading';
import image1 from '../../assets/about-us/Rectangle 70.png';
import image2 from '../../assets/about-us/Rectangle 71.png';
import image3 from '../../assets/about-us/Rectangle 72.png';
import image4 from '../../assets/about-us/WhatsApp Image 2024-08-20 at 13.55.36_2390d4b6.png';
import image5 from '../../assets/about-us/image (7).png';
import image6 from '../../assets/about-us/image (8).png';

function AboutUs() {
  return (
    <div>
      <div className="hidden md:flex mt-20 mb-12 mx-20 items-center justify-center">
        <img
          src={AboutUsPics}
          className="w-fit h-[620px] px-20 py-20 pt-0 pb-0"
        />
      </div>
      <div className="grid grid-cols-3 gap-2 md:hidden p-4">
        <img
          src={image1}
          className="w-full h-auto object-cover"
          alt="Image 1"
        />
        <img
          src={image2}
          className="w-full h-auto object-cover"
          alt="Image 2"
        />
        <img
          src={image3}
          className="w-full h-auto object-cover"
          alt="Image 3"
        />
        <img
          src={image4}
          className="w-full h-auto object-cover"
          alt="Image 4"
        />
        <img
          src={image5}
          className="w-full h-auto object-cover"
          alt="Image 5"
        />
        <img
          src={image6}
          className="w-full h-auto object-cover"
          alt="Image 6"
        />
      </div>

      <div className="mb-16 mx-6 md:mx-24 font-medium text-[12px] md:text-[18px]">
        <p className="text-[20px]">
          Airborne Geoscience International (AGI) is an Indian startup mandated
          to become India's first full service early-stage natural resource
          exploration company. AGI is currently in the process of developing its
          business infrastructure and establishing its business footprint across
          four continents internationally. At this time, AGI and its associates
          are in the middle of executing exploration survey contracts for the
          Indian Government, valued more than US$5 Million.
          <br />
          <br />
          Early-stage exploration of subsurface natural resources includes:
          <ul className="list-disc pl-5">
            <li className="pb-2">
              Base and Precious metals (Copper, Lead, Zinc, Platinum, Gold,
              Silver, etc.)
            </li>
            <li className="pb-2">
              Critical and Radioactive minerals (Titanium, Uranium, Potassium,
              etc.)
            </li>
            <li className="pb-2">
              Hydrocarbons (Oil and Gas, Coalbed, Methane, etc.)
            </li>
            <li className="pb-2">Kimberlites (Diamonds)</li>
            <li className="pb-2">
              Underground water and Geothermal sources, etc.
            </li>
          </ul>
          The above resources involves application of a wide range of airborne
          and ground technologies over large unexplored acreage, with the aim to
          identify and establish the discoveries of deep-seated natural resource
          deposits, in the most comprehensive, time efficient and cost-effective
          manner.
          <br />
          <br />
          Given the above background, AGI is in the process of developing early
          stage exploration infrastructure in India, which will form the bedrock
          for India's “Atmanirbhar Exploration” of critical and deep seated
          natural resources. Development of such infrastructure includes, but is
          not limited to, development and/or application of state-of-the-art,
          exclusive and patented survey technologies, survey aircraft and
          equipment, suitably qualified human resources, early stage exploration
          consulting, data management including data analysis etc in India, with
          a goal to make India the largest global exploration hotspot of the
          future.
        </p>
      </div>
    </div>
  );
}

export default AboutUs;
