// Add this in a global place, such as the main `App` component.
const SvgDefinitions = () => (
  <svg width="0" height="0">
    <defs>
      <linearGradient
        id="paint0_linear_343_30"
        x1="18.703"
        x2="62.85"
        y1="45.491"
        y2="45.491"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#B9852C"></stop>
        <stop offset="0.05" stopColor="#C28D2A"></stop>
        <stop offset="0.12" stopColor="#D9A323"></stop>
        <stop offset="0.17" stopColor="#EEB61D"></stop>
        <stop offset="0.44" stopColor="#FDF289"></stop>
        <stop offset="0.73" stopColor="#EEB61D"></stop>
        <stop offset="0.89" stopColor="#ECCA65"></stop>
        <stop offset="1" stopColor="#B9852C"></stop>
      </linearGradient>
    </defs>
  </svg>
);

export default SvgDefinitions;
