import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="p-4 md:p-8 md:mx-24 mx-6">
      <h1 className="font-bold text-2xl md:text-3xl mb-6">Privacy Policy</h1>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          1. Introduction
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          Welcome to AGI. At AGI, we respect your privacy and are committed to
          protecting the personal information you share with us. This Privacy
          Policy explains how we collect, use, disclose, and safeguard your
          information when you visit our website{' '}
          <a href="http://www.agiplc.com" className="text-blue-500">
            www.agiplc.com
          </a>
          . Please read this policy carefully to understand our practices
          regarding your personal data.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          2. Information We Collect
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          We may collect and process the following types of information:
        </p>
        <ul className="list-disc ml-5 text-[12px] md:text-[18px] mt-2">
          <li>
            <strong>Personal Data:</strong> This includes any information that
            can be used to identify you, such as your name, email address, phone
            number, and any other details you provide when creating an account
            or contacting us.
          </li>
          <li>
            <strong>Usage Data:</strong> We automatically collect information
            about how you use our site, such as your IP address, browser type,
            operating system, pages visited, and time spent on our site.
          </li>
          <li>
            <strong>Cookies and Tracking Technologies:</strong> We may use
            cookies, web beacons, and other tracking technologies to collect
            data and improve your experience on our site. You can adjust your
            browser settings to refuse cookies, but some features of our site
            may not function properly without them.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          3. How We Use Your Information
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          We use the information we collect for various purposes, including:
        </p>
        <ul className="list-disc ml-5 text-[12px] md:text-[18px] mt-2">
          <li>To provide and maintain our site</li>
          <li>To improve our site and your user experience</li>
          <li>To process your transactions and manage your account</li>
          <li>
            To communicate with you, including sending updates, promotional
            materials, and responses to your inquiries
          </li>
          <li>To analyze usage patterns and trends to improve our services</li>
          <li>To comply with legal obligations</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          4. Sharing Your Information
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          We do not sell, trade, or otherwise transfer your personal data to
          outside parties, except in the following cases:
        </p>
        <ul className="list-disc ml-5 text-[12px] md:text-[18px] mt-2">
          <li>
            With service providers who assist us in operating our site and
            conducting our business, provided they agree to keep your
            information confidential
          </li>
          <li>
            In response to a legal request or to comply with legal obligations
          </li>
          <li>To protect our rights, property, or safety, or that of others</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          5. Data Security
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          We take reasonable measures to protect your personal information from
          unauthorized access, alteration, disclosure, or destruction. However,
          no method of transmission over the internet or method of electronic
          storage is completely secure, so we cannot guarantee absolute
          security.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          6. Your Rights
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          You have certain rights regarding your personal data, including:
        </p>
        <ul className="list-disc ml-5 text-[12px] md:text-[18px] mt-2">
          <li>The right to access the personal data we hold about you</li>
          <li>The right to correct any inaccurate or incomplete information</li>
          <li>
            The right to request the deletion of your data, subject to certain
            exceptions
          </li>
          <li>
            The right to object to or restrict the processing of your data
          </li>
          <li>The right to data portability</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="font-medium text-[12px] md:text-[18px]">
          7. Changes to This Privacy Policy
        </h2>
        <p className="text-[12px] md:text-[18px] mt-2">
          We may update this Privacy Policy from time to time. We will notify
          you of any significant changes by posting the new policy on this page
          with an updated effective date. We encourage you to review this policy
          periodically to stay informed about how we protect your information.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
